<template>
  <div class="bank-loan-history">
    <div class="container fluid">
      <h2 class="mx-2 my-4">Bank Loan History</h2>

      <lead-history-table
        :getBankLoans="bankLoanAPI.getBankLoans"
      ></lead-history-table>
    </div>
  </div>
</template>

<script>
import LeadHistoryTable from "@/components/GlobalComponents/Shared/Banker/LeadHistoryTable";
import { bankLoan as bankLoanAPI } from "@/api";

export default {
  components: {
    LeadHistoryTable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      bankLoanAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  method: {}
};
</script>

<style lang="scss">
.bank-loan-history {
}
</style>
